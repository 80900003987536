export const Bio = {
  name: "Subha Dey",
  roles: [
    "DevOps Engineer",
    "Frontend Developer",
    "Blogger",
    "Cybersecurity Enthusiast",
    "Programmer",
  ],
  description:
    "Proficient in DevOps, Cloud, and Frontend, including analysis, design, coding, Webpage Design, scripting, testing, automation, version control, documentation and support.",
  github: "https://github.com/deysubho",
  resume:
    "https://drive.google.com/file/d/1ZyeJfE4SUs3us7EOUE_ihLd7FtV8dVB5/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/subhodey/",
  Hashnode: "https://hashnode.com/@subhodey",
  insta: "https://www.instagram.com/subho_dey_01?igsh=anlzdTB5NzQ5M3Y=",
  facebook: "https://www.facebook.com/profile.php?id=100068183766827&mibextid=eHce3h",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      //{
        //name: "React Js",
        //image:
          //"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      //},
      //{
      //  name: "Redux",
        //image:
          //"https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      //},
      
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      //{
      //  name: "JavaScript",
      //  image:
      //    "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      //},
      
    ],
  },
  {
    title: "DevOps",
    skills: [
      {
        name: "Linux",
        image: "https://upload.wikimedia.org/wikipedia/commons/3/3c/TuxFlat.svg",
      },
      {
        name: "Docker",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a7/Docker-svgrepo-com.svg",
      },
      {
        name: "Kubernetes",
        image: "https://upload.wikimedia.org/wikipedia/commons/3/39/Kubernetes_logo_without_workmark.svg",
      },
      {
        name: "AWS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      },
      {
        name: "Jenkins",
        image:
          "https://www.jenkins.io/images/logos/atomium/atomium.svg",
      },
      {
        name: "Grafana",
        image: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Grafana_icon.svg",
      },
      {
        name: "Git",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/e/e0/Git-logo.svg",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      
    ],
  },
  {
    title: "CyberSecurity",
    skills: [
      {
        name: "KaliLinux",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/1/12/Kali-linux-revealed-video-poster2-3.jpg",
      },
      {
        name: "Wireshark",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/d/df/Wireshark_icon.svg",
      },

      {
        name: "Metasploit",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/0/08/Logo_metasploit.png",
      },
      {
        name: "Nmap",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/7/73/Logo_nmap.png",
      },
      {
        name: "Aircrack",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/c3/Aircrack-ng-new-logo.jpg",
      },
      {
        name: "Burpsuite",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/2/29/Burpsuite.png",
      },
      
    ],
  },
  
  {
    title: "Programming Language",
    skills: [
      {
        name: "C++",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg",
      },
      //{
      //  name: "VS Code",
      //  image:
      //    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      //},
      {
        name: "Python",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg",
      },
      
    ],
  },
];

export const experiences = [
  //{
  //  id: 0,
  //  img: "https://i.postimg.cc/sfGmrmZ4/bit.jpg",
    //role: "Cybersecurity Intern",
  /*  company: "BitsGate",
    date: "January 2024 - Present",
    desc: "Adeptly navigating diverse tools like Wireshark, Nessus, while constantly learning new techniques, bolstering security measures, and gaining hands-on experience",
    skills: [
      "Kali Linux",
      "Wireshark",
      "Metasploit",
      "Vega",
      "Hashcat",
      "Aircrack-ng",
      "Nmap",
    ],
  },*/
  /*{
    id: 0,
    img: "https://i.postimg.cc/d1TXkYD5/cloud.jpg",
    role: "DevOps Intern",
    company: "Cloud Counselage Pvt.Ltd.",
    date: "July 2023 - October 2023",
    desc: " leveraged a multitude of DevOps tools to drive process optimization, employing an array of technologies to enhance operational efficiency and foster collaborative workflows",
    skills: [
      "Docker",
      "Kubernetes",
      "Linux",
      "Jenkins",
      "Grafana",
    
    ],
    },*/
    {
      id: 3,
      img: "https://i.postimg.cc/hjz26xbr/channels4-profile.jpg",
      role: "Member of Cloud Native Computing Foundation",
      company: "CNCF-HOOGHLY",
      date: "Aug 2023 - Present",
      desc: "Member of Cloud Native Computing Foundation (Hooghly Chapter)",
      skills: [
        "Leadership",
        "Docker",
        "Kubernetes",
        "Azure",
        "Cloud",
      ],
    },
  {
    id: 1,
    img: "https://i.postimg.cc/HkvfvFJD/Screenshot-2024-01-11-182605.jpg",
    role: "Frontend Development Intern",
    company: "Octanet",
    date: "June 2023 - July 2023",
    desc: "Accumulated invaluable hands-on experience in website and web application development, showcasing practical skills through the creation of functional projects including a todo-list application and a Netflix clone website.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "Docker",
      "AWS",
    ],
  },

];

export const education = [
  {
    id: 0,
    img: "https://i.postimg.cc/HxBLzftQ/images.jpg",
    school: "Institute of Engineering and Management , Kolkata",
    date: "August 2022 - August 2025",
    grade: "8.8 CGPA",
    desc: "I am a Computer Science and Engineering student at the Institute of Engineering and Management, Kolkata, completing five semesters with a CGPA of 8.8. My coursework includes Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others.",
    degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
  },
  {
    id: 1,
    img: "https://i.postimg.cc/3RKXdQRp/north.jpg",
    school: "North Calcutta Polytechnic",
    date: "August 2019 - July 2022",
    grade: "8.2 CGPA",
    desc: "I completed Diploma in Electronics and Instrumentation Engineering from North Calcutta Polytechnic.",
    degree: "Diploma, Electronics and Instrumentation Engineering",
  },
  {
    id: 2,
    img: "https://i.postimg.cc/sxW7LjHk/bhatibari.jpg",
    school: "Bhatibari High School",
    date: "April 2018 - Apr 2019",
    grade: "66.6%",
    desc: "I completed my Higher secondary education in Science at Bhatibari High School (H.S).",
    degree: "WBCHSE(XII), Science",
  },
  {
    id: 2,
    img: "https://i.postimg.cc/sxW7LjHk/bhatibari.jpg",
    school: "Bhatibari High School",
    date: "April 2016 - Apr 2017",
    grade: "80%",
    desc: "I completed my secondary education at Bhatibari High School (H.S).",
    degree: "WBCHSE(X)",
  },
];

export const projects = [
  {
    id: 7,
    title: "Resilient Three-Tier AWS Architecture for Real-Time Face Recognition Attendance System Deployment",
    date: "Jan 2024 - Present",
    description:
      "Building a Robust Three-Tier Architecture on AWS for Real-Time Face Recognition Attendance System with Database Integration",
    image:
      "https://i.postimg.cc/5yGxTWHz/q7dkgv0nk5grvcwg4itb.gif",
    tags: ["Python" , "AWS VPC" ,"AWS S3", "Route 53", "AWS EC2", "AWS DynamoDB" , "Git" , "AWS Auto Scaling", "AWS Cloudwatch"],
    category: "web app & DevOps",
    //github: "",+
  },
  {
    id: 9,
    title: "Netflix Clone",
    date: "Jun 2023 - Jul 2023",
    description:
      "The Netflix clone website is a fully functional replica of the popular streaming platform, Netflix. Built using HTML, CSS, and JavaScript,",
    image:
      "https://i.postimg.cc/mrB4R9zV/netflix-pic.jpg",
    tags: [
      "HTML",
      "CSS",
      
    ],
    category: "web app",
    github: "https://github.com/deysubho/Netflix-Clone.git",
    webapp: "https://659a70246270e66f7327529b--exquisite-dusk-f498ed.netlify.app/",
  },
  {
    id: 0,
    title: "To-Do-list-web-application",
    date: "Aug 2022 - Sep 2022",
    description:
      "Basic To do list web application using HTML and CSS and javascript.",
    image:
      "https://i.postimg.cc/c1pbZq6T/feature.jpg",
    tags: ["HTML",
    "CSS",
    "Javascript"],
    category: "web app",
    github: "https://github.com/deysubho/To-Do-list-web-application.git",
    
  },
  

  {
    id: 3,
    title: "k8s-app-deploy",
    date: "Oct 2023 - Nov 2023",
    description:
      "Kubernetes Flask App end to end Deployment With Microservices",
    image:
      "https://i.postimg.cc/cH8bxq6Z/e6db2780-b552-11eb-8faa-7e41d19fe049.jpg",
    tags: ["Linux", "Docker", "Kubernetes", "Postman"],
    category: "DevOps",
    github: "https://github.com/deysubho/k8s-app-deploy.git",
  },
  {
    id: 10,
    title: "Django_todo_App_CI/CD",
    date: "Dec 2023 - Dec 2023",
    description:
      "CI/CD-Enabled Deployment with Jenkins for Seamless Integration and Delivery of Django-Based Todo Web App",
    image:
      "https://i.postimg.cc/c1pbZq6T/feature.jpg",
    tags: ["Linux", "Docker", "Jenkins"],
    category: "DevOps",
    github: "https://github.com/deysubho/django-todo-web-app-cicd.git",
  },

  
  
  {
    id: 2,
    title: "Personal Keylogger & System Monitor",
    date: "Jan 2023 - Feb 2023",
    description:
      "personal keylogger and system monitor, designed for tracking keystrokes, capturing system information, and generating periodic reports via email",
    image:
      "https://i.postimg.cc/mhfy7GKw/Keylogger-Software-1400.jpg",
    tags: ["Python"],
    category: "CyberSecurity",
    github: "https://github.com/deysubho/Keylogger.git",
   
  },
  
  
];


